<template>
  <div class="container-fluid mt-3">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <Breadcrumb current_page="إضافة فيديو"/>

      <button class="btn btn-publish mb-4" type="submit" @click="saveVideo">نشر</button>
    </div>

    <el-card>


    </el-card>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default  {
  name: "Add_Video",
  components: {
    Breadcrumb
  },
  methods: {
    saveVideo() {

    }
  }
}
</script>
